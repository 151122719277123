// store
import store from '@/store'
import { getReferenceValue } from '@/store/utils'

// utils
import { numberFormatter, currencyFormatter } from '@/utils/format-helpers'
import { sortAlpha } from '@/utils/sort'

// types
import { CommissionPayPeriod, CommissionQuotaPeriod } from '@/types'

export type Chart = {
  type: ChartType
  horizontal: boolean
  sortDesc: boolean

  where: Query

  amountFieldId: string
  rowFieldId: string
  columnFieldId?: string
}

export const axisTypes = ['category', 'datetime', 'numeric'] as const
export type AxisType = typeof axisTypes[number]

export const chartTypes = [
  { id: 'bar', icon: 'mdi-chart-bar', label: 'Bar' },
  { id: 'line', icon: 'mdi-chart-line', label: 'Line' },
  { id: 'area', icon: 'mdi-chart-areaspline', label: 'Area' },
  { id: 'treemap', icon: 'mdi-chart-tree', label: 'Treemap' },
] as const
export type ChartType = 'bar' | 'line' | 'area' | 'treemap'

export type Query = {
  [key: string]: number | string | (number | string)[]
}

export type FinderFunction<R = string | number> = (id: string | number) => R
export type FormatterFunction = (
  value: number | undefined | null
) => string | number
export type SortValueFn = (
  data: ReadonlyArray<number | null>,
  id: number | string
) => number
export type DataLabelPosition = {
  position?: 'top' | 'center' | 'bottom'
  textAnchor?: 'start' | 'middle' | 'end'
  offsetX?: number
  offsetY?: number
}

export const DEFAULT_FINDER: FinderFunction = (input: string | number) =>
  input.toString()

export const DEFAULT_SORT_VALUE_FN: SortValueFn = (
  data: ReadonlyArray<number | null>
) => data.reduce((prev, v) => (prev || 0) + (v || 0)) || 0

export const DEFAULT_AMOUNT_FORMATTER: FormatterFunction = numberFormatter(0)

export type AmountField = {
  id: string
  label: string
  sortDesc?: boolean
  formatter?: (
    where: Query
  ) => (value: number | undefined | null) => string | number
  dataLabelFormatter?: (
    where: Query
  ) => (value: number | undefined | null) => string | number
  axisFormatter?: (
    where: Query
  ) => (value: number | undefined | null) => string | number
}

const currencyFn = (options: Intl.NumberFormatOptions = {}) => (
  where: Query
) => (value: number | undefined | null) =>
  currencyFormatter(
    (where.currency || 'USD').toString(),
    options,
    ''
  )(value || 0)

const scaleCurrencyFn = (
  mult: number,
  resultFn: (result: string) => string
) => (where: Query) => (value: number | null | undefined) =>
  resultFn(
    currencyFormatter((where.currency || 'USD').toString(), {
      maximumFractionDigits: 0,
    })((value || 0) / mult)
  )

export const amountFields: AmountField[] = [
  {
    id: 'postedAmount',
    label: 'Posted',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: true,
  },
  {
    id: 'unpostedAmount',
    label: 'Unposted',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: true,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    formatter: () => numberFormatter(0),
    dataLabelFormatter: () => numberFormatter(0),
    axisFormatter: () => numberFormatter(0),
    sortDesc: true,
  },
  {
    id: 'netAmount',
    label: 'Net',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: true,
  },
  {
    id: 'revenueAmount',
    label: 'Revenue',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: true,
  },
  {
    id: 'grossAmount',
    label: 'Gross',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: true,
  },
  {
    id: 'fmvAdjustmentAmount',
    label: 'FMV Adjustment',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: false,
  },
  {
    id: 'adjustmentAmount',
    label: 'Adjustment',
    formatter: currencyFn(),
    dataLabelFormatter: currencyFn({ maximumFractionDigits: 0 }),
    axisFormatter: scaleCurrencyFn(1000, value => `${value}k`),
    sortDesc: false,
  },
]

export type CategoryField<FinderResult = string | number> = {
  id: string
  label: string
  axisType: AxisType
  categories?: (where: Query) => Array<string | number>
  finder?: FinderFunction<FinderResult>
  sorter?: SortValueFn
}

export const categoryFields: CategoryField[] = [
  {
    id: 'productId',
    label: 'Product',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getProductById'),
  },
  {
    id: 'productCategoryId',
    label: 'Product Category',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getProductCategoryById'),
  },
  {
    id: 'companyId',
    label: 'Company',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getCompanyById'),
  },
  {
    id: 'customerId',
    label: 'Customer',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getCustomerById'),
  },
  {
    id: 'customerTypeId',
    label: 'Customer Type',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getCustomerTypeById'),
  },
  {
    id: 'shippingTermId',
    label: 'Ship Term',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getShippingTermById'),
  },
  {
    id: 'commissionComponentId',
    label: 'Commission Component',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getCommissionComponentById'),
  },

  {
    id: 'commissionQuotaPeriodId',
    label: 'Commission Quota Period',
    axisType: 'category',
    categories: where =>
      store.getters.commissionQuotaPeriods
        .filter(
          (z: CommissionQuotaPeriod) =>
            !where ||
            !where.commissionQuotaPeriodId ||
            (Array.isArray(where.commissionQuotaPeriodId)
              ? where.commissionQuotaPeriodId.includes(z.id)
              : where.commissionQuotaPeriodId === z.id)
        )
        .map((z: CommissionQuotaPeriod) => z.id),
    finder: id => getReferenceValue(id, 'getCommissionQuotaPeriodById'),
  },
  {
    id: 'commissionPayPeriodId',
    label: 'Commission Pay Period',
    axisType: 'category',
    categories: where =>
      store.getters.commissionPayPeriods
        .filter(
          (z: CommissionPayPeriod) =>
            !where ||
            !where.commissionQuotaPeriodId ||
            (Array.isArray(where.commissionQuotaPeriodId)
              ? where.commissionQuotaPeriodId.includes(
                  z.commissionQuotaPeriod.id
                )
              : where.commissionQuotaPeriodId === z.commissionQuotaPeriod.id)
        )
        .sort(sortAlpha('startDate'))
        .map((z: CommissionPayPeriod) => z.id),
    finder: id => getReferenceValue(id, 'getCommissionPayPeriodById'),
  },

  {
    id: 'commissionQuotaPeriodPostedDay',
    label: 'Day (Commission Quota Period)',
    axisType: 'numeric',
    finder: value => value || 0,
    sorter: (_, id) => id as number,
  },
  {
    id: 'commissionPayPeriodPostedDay',
    label: 'Day (Commission Pay Period)',
    axisType: 'numeric',
    finder: value => value || 0,
    sorter: (_, id) => id as number,
  },
  {
    id: 'commissionPayPeriodPeriodNumber',
    label: 'Period Number (Commission Pay Period)',
    axisType: 'numeric',
    finder: value => value || 0,
    sorter: (_, id) => id as number,
  },
  {
    id: 'commissionPayPeriodPeriodGroup4',
    label: 'Period Group 4 (Commission Pay Period)',
    axisType: 'numeric',
    finder: value => value || 0,
    sorter: (_, id) => id as number,
  },
  {
    id: 'commissionPayPeriodPeriodGroup2',
    label: 'Period Group 2 (Commission Pay Period)',
    axisType: 'numeric',
    finder: value => value || 0,
    sorter: (_, id) => id as number,
  },
  {
    id: 'postedDate',
    label: 'Posted Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'postedMonth',
    label: 'Posted Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'postedYear',
    label: 'Posted Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'postedMonthYear',
    label: 'Posted Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estPostedDate',
    label: 'Est Posted Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'estPostedMonth',
    label: 'Est Posted Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estPostedYear',
    label: 'Est Posted Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estPostedMonthYear',
    label: 'Est Posted Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'receivedDate',
    label: 'Received Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'receivedMonth',
    label: 'Received Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'receivedYear',
    label: 'Received Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'receivedMonthYear',
    label: 'Received Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'processedDate',
    label: 'Processed Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'processedMonth',
    label: 'Processed Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'processedYear',
    label: 'Processed Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'processedMonthYear',
    label: 'Processed Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'shippedDate',
    label: 'Shipped Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'shippedMonth',
    label: 'Shipped Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'shippedYear',
    label: 'Shipped Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'shippedMonthYear',
    label: 'Shipped Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estShippedDate',
    label: 'Est Shipped Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'estShippedMonth',
    label: 'Est Shipped Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estShippedYear',
    label: 'Est Shipped Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estShippedMonthYear',
    label: 'Est Shipped Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'deliveredDate',
    label: 'Delivered Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'deliveredMonth',
    label: 'Delivered Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'deliveredYear',
    label: 'Delivered Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'deliveredMonthYear',
    label: 'Delivered Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estDeliveredDate',
    label: 'Est Delivered Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'estDeliveredMonth',
    label: 'Est Delivered Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estDeliveredYear',
    label: 'Est Delivered Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estDeliveredMonthYear',
    label: 'Est Delivered Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'installedDate',
    label: 'Installed Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'installedMonth',
    label: 'Installed Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'installedYear',
    label: 'Installed Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'installedMonthYear',
    label: 'Installed Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estInstalledDate',
    label: 'Est Installed Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'estInstalledMonth',
    label: 'Est Installed Month',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estInstalledYear',
    label: 'Est Installed Year',
    axisType: 'numeric',
    sorter: (_, id) => id as number,
  },
  {
    id: 'estInstalledMonthYear',
    label: 'Est Installed Year-Month',
    axisType: 'category',
    sorter: (_, id) => id as number,
  },
  {
    id: 'overriedPostedDate',
    label: 'Override Posted Date',
    axisType: 'datetime',
    finder: value => value || new Date().getTime(),
    sorter: (_, id) => id as number,
  },
  {
    id: 'orderTypeId',
    label: 'Order Type',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getOrderTypeById'),
  },
  {
    id: 'orderCategoryId',
    label: 'Order Category',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getOrderCategoryById'),
  },
  {
    id: 'shippingTermId',
    label: 'Shipping Term',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getShippingTermById'),
  },
  {
    id: 'salesAreaId',
    label: 'Sales Area',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getSalesAreaById'),
  },
  {
    id: 'salespersonId',
    label: 'Salesperson',
    axisType: 'category',
    finder: id => getReferenceValue(id, 'getSalespersonById'),
  },
  {
    id: 'currency',
    label: 'Currency',
    axisType: 'category',
  },
  {
    id: 'quotaNo',
    label: 'Quota No',
    axisType: 'category',
  },
  {
    id: 'orderNo',
    label: 'Order No',
    axisType: 'category',
  },
  {
    id: 'poNo',
    label: 'PO No',
    axisType: 'category',
  },
  {
    id: 'serialNo',
    label: 'Serial No',
    axisType: 'category',
  },
  {
    id: 'isPosted',
    label: 'Is Posted',
    axisType: 'category',
    finder: value => (value === 1 ? 'true' : value === 0 ? 'false' : 'unknown'),
  },
  {
    id: 'postedTrigger',
    label: 'Posted Trigger',
    axisType: 'category',
  },

  {
    id: 'deliveryAddressId',
    label: 'Delivery Address',
    axisType: 'category',
    finder: value =>
      store.state.customers
        .find(c => c.addresses.some(a => a.id === value))
        ?.addresses.find(a => a.id === value)?.displayAddress,
  },
  {
    id: 'cityStateCountry',
    label: 'City (Full)',
    axisType: 'category',
    finder: value => value,
  },
  {
    id: 'countyStateCountry',
    label: 'County (Full)',
    axisType: 'category',
    finder: value => value,
  },
  {
    id: 'state',
    label: 'State',
    axisType: 'category',
    finder: value => value,
  },
  {
    id: 'country',
    label: 'Country',
    axisType: 'category',
    finder: value => value,
  },
  {
    id: 'zip',
    label: 'Zip',
    axisType: 'category',
    finder: value => value,
  },
]
