




























































import Vue, { PropType } from 'vue'

import {
  FieldReference,
  FieldReferenceLookup,
} from '@/components/orders/order-fields'
import { Operator, FilterValue } from './FilterForm.vue'

import { sortAlpha } from '@/utils/sort'

type FieldReferenceItem = FieldReference & { id: string }

export default Vue.extend({
  props: {
    field: String,
    value: [String, Number, Array] as PropType<FilterValue>,
    index: Number,

    fields: Object as PropType<FieldReferenceLookup>,
  },
  data: () => ({
    operators: ['=', '<>'] as string[],
  }),
  watch: {},
  computed: {
    fieldId(): string {
      return this.field.split('!')[0]
    },
    operator(): Operator {
      return this.field.split('!').length === 2 ? '<>' : '='
    },
    fieldList(): FieldReferenceItem[] {
      return Object.entries(this.fields)
        .filter(([, value]) => !value.notFilterable)
        .map(([key, value]) => ({
          ...value,
          id: key,
        }))
        .sort(sortAlpha('label'))
    },
  },
  methods: {
    onUpdateField(fieldId: string): void {
      this.$emit('update', fieldId, this.operator, null, this.index)
    },
    onUpdateOperator(operator: string): void {
      this.$emit('update', this.fieldId, operator, this.value, this.index)
    },
    onUpdateValue(value: FilterValue): void {
      this.$emit('update', this.fieldId, this.operator, value, this.index)
    },
    onDelete(): void {
      this.$emit('delete', this.index)
    },
  },
})
