


































































































import Vue from 'vue'

import MapExplorerOptions from '@/components/explorer/MapExplorerOptions.vue'
import OrderDetailsCard from '@/components/orders/OrderDetailsCard.vue'
import JQueryChips from '@/components/controls/JQueryChips.vue'
import LocationMap, {
  Marker as MapMarker,
} from '@/components/maps/LocationMap.vue'

import {
  MapConfig,
  amountFields,
  categoryFields,
  DEFAULT_FINDER,
  DEFAULT_AMOUNT_FORMATTER,
  AmountField,
  CategoryField,
  Pivot2Data,
  mapScales,
  MapScale,
  Pivot2DataDatum,
} from '@/components/charts/map-config-settings'

import {
  encodeConfig,
  decodeConfig,
} from '@/components/explorer/explorer-encoder'

import cloneDeep from 'lodash.clonedeep'
import MessageBus from '@/message-bus'
import { Customer, CustomerAddress } from '@/types'
import { currencyFormatter } from '@/utils/format-helpers'
import { Query } from '../charts/chart-settings'

const DEFAULT_SCALE: MapScale = 'linear'
const DEFAULT_AMOUNT_FIELD_ID = 'postedAmount'
const DEFAULT_ROW_FIELD_ID = ['customerId', 'deliveryAddressId', 'currency'] // ['lat', 'lng'] // 'commissionPayPeriodId'

// TODO: what to do with negative values?
// const scale = (min: number, max: number, val: number) =>
//   (max - min - Math.max(min, val)) / (max - min)

export default Vue.extend({
  metaInfo: {
    title: 'Map Explorer',
  },
  components: {
    MapExplorerOptions,
    JQueryChips,
    LocationMap,
    OrderDetailsCard,
  },
  data() {
    return {
      mapConfig: {
        scale: DEFAULT_SCALE,
        showDataLabels: false,

        where: {
          isPosted: [1],
          salespersonId: [this.$auth.user?.id],
          commissionQuotaPeriodId: [new Date().getUTCFullYear()],
        },

        amountFieldId: DEFAULT_AMOUNT_FIELD_ID,
        rowFieldIds: DEFAULT_ROW_FIELD_ID,
      } as MapConfig,

      data: undefined as Pivot2Data | undefined,

      editMapConfig: undefined as MapConfig | undefined,

      showMap: true,
      fullscreenMap: false,
      showTable: false,

      DEFAULT_FINDER: DEFAULT_FINDER,
      DEFAULT_AMOUNT_FORMATTER: DEFAULT_AMOUNT_FORMATTER,

      detailsDialogQuery: null as null | Query,
    }
  },
  computed: {
    mapMarkers(): MapMarker[] {
      if (!this.data || !this.rowFields) return []
      const rowFields = this.rowFields
      const customerFinder = rowFields[0].finder || DEFAULT_FINDER
      const addressFinder = rowFields[1].finder || DEFAULT_FINDER
      const currencyFinder = rowFields[2].finder || DEFAULT_FINDER
      const locationFinder = (value: number) =>
        this.$store.state.customers
          .find((c: Customer) =>
            c.addresses.some((a: CustomerAddress) => a.id === value)
          )
          ?.addresses.find((a: CustomerAddress) => a.id === value).location || {
          lat: 0,
          lng: 0,
        }

      const markers = this.data.data
        .filter(d => !!d.row[1])
        .map(d => {
          // TODO: deal with variable columnFields
          return {
            location: locationFinder(d.row[1] as number),
            title:
              customerFinder(d.row[0]).toString() +
              ': ' +
              currencyFormatter(currencyFinder(d.row[2]).toString())(d.value),
            caption: (addressFinder(d.row[1]) || '').toString(),
            data: d,
            size: mapScales[this.mapConfig.scale](d, this.data, this.mapConfig),
          }
        })
      return markers.sort((a, b) => b.size - a.size)
    },
    dialog(): boolean {
      return !!this.editMapConfig
    },
    detailsDialog(): boolean {
      return !!this.detailsDialogQuery
    },
    mapConfigIsValid(): boolean {
      return (
        this.mapConfig &&
        Object.keys(this.mapConfig).length > 0 &&
        !!this.amountField &&
        !!this.rowFields
      )
    },

    amountField(): AmountField | undefined {
      return amountFields.find(v => v.id === this.mapConfig.amountFieldId)
    },
    rowFields(): CategoryField[] | undefined {
      return this.mapConfig.rowFieldIds
        .map(rowFieldId => categoryFields.find(v => v.id === rowFieldId))
        .filter((v): v is CategoryField => !!v)
    },
    columnFields(): CategoryField[] | undefined {
      return (this.mapConfig.columnFieldIds || [])
        .map(columnFieldId => categoryFields.find(v => v.id === columnFieldId))
        .filter((v): v is CategoryField => !!v)
    },
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.v &&
      typeof this.$route.query.v === 'string'
    )
      this.applyConfig(decodeConfig(this.$route.query.v))
  },
  methods: {
    fetch(v: MapConfig): Promise<Pivot2Data> {
      return this.$api.charts.pivot
        .get2(v.amountFieldId, v.rowFieldIds, v.columnFieldIds, v.where)
        .then(data => {
          return data
        })
        .catch(err => {
          console.error(err)
          MessageBus.error(err)
          throw Error(err)
        })
    },
    openDialog(): void {
      this.editMapConfig = cloneDeep(this.mapConfig)
    },
    closeDialog(): void {
      this.editMapConfig = undefined
    },
    openDetailsDialog(q: Query): void {
      this.detailsDialogQuery = q
    },
    closeDetailsDialog(): void {
      this.detailsDialogQuery = null
    },
    applyConfig(v: MapConfig) {
      return this.fetch(v).then(data => {
        this.data = data
        this.mapConfig = v
      })
    },
    applyDialog(v: MapConfig, close = true): void {
      this.$router.replace({ query: { v: encodeConfig(v) } })
      this.applyConfig(v).then(() => {
        if (close) this.closeDialog()
      })
    },
    setFullscreenMap(value: boolean) {
      this.fullscreenMap = value
      this.$nextTick().then(() => {
        // required for LocationMap component to redraw new map tiles
        window.dispatchEvent(new Event('resize'))
      })
    },
    onClickMarker(m: MapMarker<Pivot2DataDatum>) {
      const q = {
        ...this.mapConfig.where,
        ...this.mapConfig.rowFieldIds.reduce(
          (prev, rowFieldId, i) => ({
            ...prev,
            [rowFieldId]: m.data?.row[i],
          }),
          {}
        ),
        ...(this.mapConfig.columnFieldIds || []).reduce(
          (prev, columnFieldId, i) => ({
            ...prev,
            [columnFieldId]: m.data?.column[i],
          }),
          {}
        ),
      }

      this.openDetailsDialog(q)
    },
    refresh(): void {
      MessageBus.error('not implemented: refresh')
    },
  },
})
