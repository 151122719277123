
























import Vue, { PropType } from 'vue'

import {
  FieldReferenceLookup,
  orderDetailFields,
} from '@/components/orders/order-fields'

import FilterFormItem from './FilterFormItem.vue'

export type FilterItem = {
  field: string
  operator: Operator
  value: (number | string)[]
}

export type FilterValue = (number | string)[] | number | string
export type Operator = '=' | '<>'

type FilterObj = {
  [field: string]: FilterValue
}

export default Vue.extend({
  components: { FilterFormItem },
  props: {
    value: Object as PropType<FilterObj>,
    fields: {
      type: Object as PropType<FieldReferenceLookup>,
      default: () => orderDetailFields,
    },
  },
  data: () => ({}),
  computed: {
    valueArray(): [string, FilterValue][] {
      return Object.entries(this.value)
    },
  },
  methods: {
    addItem(): void {
      const obj = {
        ...this.value,
        _: null,
      }

      this.$emit('input', obj)
    },
    onUpdate(
      fieldId: string,
      operator: string,
      value: FilterValue,
      index: number
    ): void {
      const field = fieldId + (operator === '<>' ? '!' : '')

      const obj = this.valueArray.reduce(
        (prev, [key, v], i) => ({
          ...prev,
          ...(index === i ? { [field]: value } : { [key]: v }),
        }),
        {}
      )

      this.$emit('input', obj)
    },
    onDelete(index: number): void {
      const obj = this.valueArray.reduce(
        (prev, [key, v], i) => ({
          ...prev,
          ...(index === i ? {} : { [key]: v }),
        }),
        {}
      )

      this.$emit('input', obj)
    },
  },
})
