import {
  CategoryField as ChartCategoryField,
  AmountField as ChartAmountField,
  Query,
} from '@/components/charts/chart-settings'

export {
  amountFields,
  categoryFields,
  DEFAULT_FINDER,
  DEFAULT_AMOUNT_FORMATTER,
} from '@/components/charts/chart-settings'

export type CategoryField = ChartCategoryField
export type AmountField = ChartAmountField

export type Pivot2DataDatum = {
  row: (string | number)[]
  column: (string | number)[]
  value: number
}
export type Pivot2Data = {
  query: {
    rows: (keyof CategoryField)[]
    columns: (keyof CategoryField)[]
    amount: keyof AmountField
  }
  data: Pivot2DataDatum[]
}

export const mapScales = {
  constant: () => 16,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  linear: (datum: Pivot2DataDatum, _data?: Pivot2Data, _config?: MapConfig) =>
    Math.max(4, Math.abs(datum.value) / 10000),
}
export type MapScale = keyof typeof mapScales

export type MapConfig = {
  scale: MapScale

  where: Query

  amountFieldId: string
  rowFieldIds: string[]
  columnFieldIds?: string[]
}
